import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Pie } from 'react-chartjs-2';
import { translate } from "../../utils/translate";
import { getAdminDashboard } from "../../services/ApiService";

const Dashboard = (props) => {
    const lang = props.lang;

    const [stats, setStats] = useState({
        totalCustomer: 0,
        genderRatio: {
            male: 0,
            female: 0
        },
        companyStats: []
    });

    async function getDashboardStats() {
        let query = {};
        let res = await getAdminDashboard(query);
        if (res?.status === 'success') {
            const { totalCustomer, totalGender, companyStats } = res.data;
            setStats({
                totalCustomer,
                genderRatio: totalGender,
                companyStats
            });
        }
    }

    useEffect(() => {
        getDashboardStats();
    }, []);

    const genderData = {
        labels: ['Male', 'Female'],
        datasets: [
            {
                label: 'Gender Distribution',
                data: [stats.genderRatio.male, stats.genderRatio.female],
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384']
            }
        ]
    };

    const companyData = {
        labels: stats.companyStats.map(stat => stat.companyName),
        datasets: [
            {
                label: 'Company Distribution',
                data: stats.companyStats.map(stat => stat.userCount),
                backgroundColor: [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                    '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'
                ],
                hoverBackgroundColor: [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                    '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'
                ]
            }
        ]
    };

    return (
        <>
            <div className="row">
                {props.auth.auth.data.role === "admin" ? (
                    <>
                        <div className="col-xl-6 col-sm-6">
                            <div className="card gradient-1 shadow-black card-bx">
                                <div className="card-body">
                                    <h5 className="card-title">{translate(lang, 'totalCustomer')}: {stats.totalCustomer}</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-sm-6">
                            <div className="card gradient-1 shadow-black card-bx">
                                <div className="card-body">
                                    <h5 className="card-title">Gender Distribution</h5>
                                    <Pie data={genderData} />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-sm-6">
                            <div className="card gradient-1 shadow-black card-bx">
                                <div className="card-body">
                                    <h5 className="card-title">Insurance Distribution</h5>
                                    <Pie data={companyData} />
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    };
};

export default connect(mapStateToProps)(Dashboard);
